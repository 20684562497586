@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background: #101139;
  padding: 0;
  margin: 0;
  font-family: "Poppins";
}


input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(0);
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 12px;
  
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
}


::-webkit-scrollbar-thumb {
  background-color: rgb(156 163 175);
  border-radius: 12px;
}


a {
  color: inherit;
  text-decoration: none;
  
}

* {
  box-sizing: border-box;
}


@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
